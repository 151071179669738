// Servis.js

import React from 'react';
import styles from '../styles/Servis.module.css';

function Servis() {

    return (
        <div className={styles.servisContainer}>
            <header className={styles.header} >
            </header>


            <div className={styles.overlay}>
                <p className={styles.subtitle}>Partneri juaj i besuar në shërbimet e riparimit të makinave</p>
            </div>

            <section className={styles.servisContent}>
                <div className={styles.dynamicSection}>
                    <img
                        src="/Photos/garage_outside.jpg"
                        alt="Garage Design 1"
                        className={styles.dynamicImage}
                    />
                    <div className={styles.dynamicText}>
                        <h2>Mirsevini ne Auto Servis Auto Plus</h2>
                        <p>
                            Në Auto Servis Auto Plus, ne jemi të përkushtuar për të ofruar shërbime të nivelit të lartë të rregullimit të makinave.
                            Ekipi ynë me përvojë i profesionistëve është i përkushtuar për të siguruar që automjeti juaj të jetë brenda
                            gjendje e shkëlqyer. Me vite ekspertizë dhe një përkushtim ndaj cilësisë, ne jemi
                            partneri juaj i besuar për të gjitha nevojat tuaja për riparimin e makinës.
                        </p>
                    </div>
                </div>

                <div className={styles.dynamicSection}>
                    <div className={styles.dynamicText}>
                        <h2>Ekspertiza jonë</h2>
                        <p>
                            Teknikët tanë të aftë kanë grumbulluar vite përvojë në diagnostikimin dhe
                            rregullimin e problemeve të ndryshme të makinës. Qofshin problemet e motorit, problemet elektrike,
                            ose mirëmbajtje të përgjithshme, ne kemi njohuritë dhe mjetet për të marrë automjetin tuaj
                            kthehemi në rrugë të sigurt.
                        </p>
                    </div>
                    <img
                        src="/Photos/modern_cars_inside_garage.jpg"
                        alt="Garage Design 2"
                        className={styles.dynamicImage}
                    />
                </div>

                <div className={styles.dynamicSection}>
                    <div className={styles.dynamicText}>
                        <h2>Mjete Profesionale</h2>
                        <p>
                            Të pajisur me mjete moderne, ne sigurojmë saktësi dhe efikasitet në
                            çdo riparim. Angazhimi ynë për të përdorur mjetet më të mira në industri reflekton
                            përkushtimi ynë për të ofruar shërbime të cilësisë së lartë të riparimit të makinave.
                        </p>
                    </div>
                    <img
                        src="/Photos/tools_photo.jpg"
                        alt="Garage Design 4"
                        className={styles.dynamicImage}
                    />
                </div>

                <div className={styles.dynamicSection}>
                    <img
                        src="/Photos/other_services_photo.jpeg"
                        alt="Garage Design 5"
                        className={styles.dynamicImage}
                    />
                    <div className={styles.dynamicText}>
                        <h2>Shërbime shtesë</h2>
                        <p>
                            Përveç shërbimeve tona kryesore, ne ofrojmë një sërë shërbimesh shtesë për t'u përmbushur
                            të gjitha nevojat për kujdesin e makinës suaj. Nga diagnostikimi te mirëmbajtja e rregullt, ne ju kemi mbuluar.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Servis;