// AboutUs.js

import React from 'react';
import styles from '../styles/aboutUs.module.css';

const AboutUs = () => {

    const position = [42.359556, 20.787593];


    return (
        <div className={styles.container}>
            <div className={styles.hero}>
                <h1 className={styles.title}>Mirsevini ne AutoPlus</h1>
                <p className={styles.subtitle}>Zgjidhja e jote per makina</p>
            </div>

            <div className={styles.section}>
                <div className={styles.subSection}>
                    <h2 className={styles.sectionTitle}>Visioni yne</h2>
                    <p className={styles.sectionSubtitle}>
                        Në thelb të AutoPlus qëndron një vizion për të ripërcaktuar përvojën e automobilave...
                    </p>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.subSection}>
                    <h2 className={styles.sectionTitle}>Serviset tona</h2>
                    <div className={styles.servicesContainer}>
                        <div className={styles.serviceCard}>
                            <h3>Rent a Car</h3>
                            <p>
                                Nisni udhëtimin tuaj me shërbimet tona premium të makinave me qira...
                            </p>
                        </div>
                        <div className={styles.serviceCard}>
                            <h3>Vullkanizer</h3>
                            <p>
                                Zgjatni jetëgjatësinë e gomave tuaja me shërbimet tona më të fundit të ritretjes...
                            </p>
                        </div>
                        <div className={styles.serviceCard}>
                            <h3>Shitje e makinave</h3>
                            <p>
                                Po kërkoni të blini një makinë? Eksploroni përzgjedhjen tonë të kuruar të automjeteve të zotëruara më parë...
                            </p>
                        </div>
                        <div className={styles.serviceCard}>
                            <h3>Servisim i makines</h3>
                            <p>
                                Kur bëhet fjalë për riparimet e makinave, besoni ekspertët e AutoPlus...
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.subSection}>
                    <h2 className={styles.sectionTitle}>Angazhimi ynë</h2>
                    <p className={styles.sectionSubtitle}>
                        Në AutoPlus, ne jemi të përkushtuar ndaj përsosmërisë në çdo aspekt...
                    </p>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.subSection}>
                    <h2 className={styles.sectionTitle}>Pse me na zgjedh ne?</h2>
                    <div className={styles.whyChooseContainer}>
                        <div className={styles.whyChooseCard}>
                            <h3>Ekesperienca</h3>
                            <p>
                                Me eksperiencë shumëvjeçare në industrinë e automobilave...
                            </p>
                        </div>
                        <div className={styles.whyChooseCard}>
                            <h3>Qasje në qendër të klientit</h3>
                            <p>
                                Kënaqësia juaj është suksesi ynë. Ne i japim prioritet nevojave tuaja...
                            </p>
                        </div>
                        <div className={styles.whyChooseCard}>
                            <h3>Sigurimi i Cilësisë</h3>
                            <p>
                                Nga flota jonë e marrjes me qira te shërbimet e vullkanizerit dhe shitjet e automjeteve...
                            </p>
                        </div>
                        {/* <div className={styles.whyChooseCard}>
                            <h3>Innovation</h3>
                            <p>
                                Embracing technological advancements, we stay ahead of the curve...
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className={`section ${styles.contactSection}`}>
                <div className={styles.subSection}>
                    {/* <h2 className={styles.sectionTitle}>Our Location</h2>
                    <div className={styles.mapContainer}>
                    </div> */}

                    <h2 className={styles.sectionTitle}>Na kontaktoni</h2>
                    <p className={styles.sectionSubtitle}>
                        Gati për të përjetuar ndryshimin me AutoPlus? Na kontaktoni sot...
                    </p>

                    {/* Contact Info Cards */}
                    <div className={styles.contactInfo}>
                        <div className={`card ${styles.contactInfoCard}`}>
                            <p>Telefon: +383 49 724 121</p>
                        </div>
                        <div className={`card ${styles.contactInfoCard}`}>
                            <p>Email: autoplus.rks@gmail.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
