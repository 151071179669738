import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useState } from 'react';

export default function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <nav className={`nav ${isMenuOpen ? "mobile-menu-open" : ""}`}>
            <Link to="/" className="site-title">
                Auto Plus
            </Link>
            <div className="menu-icon" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul className={isMenuOpen ? "open" : ""}>
                <CustomLink to="/servis" onClick={closeMenu}>Servis</CustomLink>
                <CustomLink to="/vullkanizer" onClick={closeMenu}>Vullkanizer</CustomLink>
                <CustomLink to="/pastrim" onClick={closeMenu}>Pastrim i makinave</CustomLink>
                <CustomLink to="/aboutUs" onClick={closeMenu}>Rreth nesh</CustomLink>
            </ul>
        </nav>
    );
}

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    );
}
