import Navbar from "./Navbar"
import RentCar from "./pages/RentCar"
import Vullkanizer from "./pages/Vullkanizer"
import Servis from "./pages/Servis"
import BlejMakin from "./pages/BlejMakin"
import { Route, Routes } from "react-router-dom"
import CarDetails from "./pages/CarDetails"
import ReserveCar from "./pages/ReserveCar"
import CheckoutCar from "./pages/CheckoutCar"
import AboutUs from "./pages/AboutUs"
import { ReservationProvider } from './ReservationContext';
import CarCleaningPage from "./pages/CarCleaningPage"
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "./pages/Footer"


function App() {
    return (
        <>
            <Navbar />
            <div className="container">
                <ReservationProvider>
                    <Routes>
                        <Route path="/" element={<Servis />} />
                        {/* <Route path="/" element={<Vullkanizer />} /> */}
                        {/* <Route path="/cars/:carId" element={<CarDetails />}></Route> */}
                        {/* <Route path="/reserve-car/:carId" element={<ReserveCar />}></Route> */}
                        {/* <Route path="/checkout" element={<CheckoutCar />}></Route> */}
                        <Route path="/vullkanizer" element={<Vullkanizer />} />
                        <Route path="/servis" element={<Servis />} />
                        {/* <Route path="/blerje" element={<BlejMakin />} /> */}
                        <Route path="/pastrim" element={<CarCleaningPage />} />
                        <Route path="/aboutUs" element={<AboutUs />} />
                    </Routes>
                </ReservationProvider>
            </div>
            <Footer />
        </>
    )
}

export default App