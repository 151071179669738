import React, { useState } from 'react';
import { useReservationContext } from '../ReservationContext';
import styles from '../styles/checkoutCar.module.css';
import CreditCardInput from '../Components/CreditCardInput'; // Make sure to import the CreditCardInput component

function CheckoutCar() {
    const { reservationData } = useReservationContext();


    const [creditCardInfo, setCreditCardInfo] = useState({
        cardNumber: '',
        expirationDate: '',
        cvv: '',
        cardholderName: '',
    });

    if (!reservationData) {
        return <p>No reservation data found</p>;
    }

    const { carId, startDate, endDate } = reservationData;
    const numberOfDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;


    const handleCreditCardChange = (field, value) => {
        setCreditCardInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
    };

    const carData = {
        id: carId,
        title: 'Golf 7 2018',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
        price: 30,
        imageUrl: 'https://uploads.vw-mms.de/system/production/images/vwn/075/980/images/bb7baec2ff01596a5bf00793b11a7e41c6c8c368/DB2023AU00177_web_1600.jpg?1678883122',
    };

    const totalPrice = numberOfDays * carData.price;


    const handleConfirm = () => {
        console.log('Reservation confirmed!');
        // Add logic to handle confirmation, e.g., make API calls or navigate to a success page
    };

    return (
        <div className={styles.container}>
            <div className={styles.section}>

                <div className={styles.carContainer}>
                    <img src={carData.imageUrl} alt="Car Photo" className={styles.carImage} />
                    <div className={styles.carDetails}>
                        <h2>{carData.title}</h2>
                        <p>{carData.description}</p>
                        <p>Cmimi: ${carData.price} per dit</p>
                    </div>
                </div>
            </div>

            <div className={styles.section}>
                <h2 className={styles.heading}>Informata shtese</h2>
                <p className={styles.summaryText}>
                    Per te konfirmuar rezervimin e {carData.title} nga {startDate.toDateString()} deri {endDate.toDateString()},
                    ju duhet ta kryeni gjysmen e pageses tani online.
                    <br />Ndersa pjesen tjeter ne momentin qe e merrni makinen
                </p>
            </div>

            <div className={styles.section}>
                <h2 className={styles.heading}>Detajet e pageses</h2>
                <div className={styles.cardContainer}>
                    <CreditCardInput
                        cardNumber={creditCardInfo.cardNumber}
                        expirationDate={creditCardInfo.expirationDate}
                        cvv={creditCardInfo.cvv}
                        cardholderName={creditCardInfo.cardholderName}
                        onInputChange={handleCreditCardChange}
                    />
                </div>
            </div>

            <div className={styles.section}>
                <h2 className={styles.heading}>Permbledhja e porosise</h2>
                <div className={styles.summaryContainer}>
                    <p className={styles.summaryText}>
                        Rezervim per {carData.title} nga {startDate.toDateString()} deri {endDate.toDateString()} ({numberOfDays} dit)
                    </p>
                    <p className={styles.summaryText}>Totali: ${totalPrice.toFixed(2)}</p>
                    <p className={styles.summaryText}>Pagesa e tanishme: ${(totalPrice / 2).toFixed(2)}</p>
                    <button type="button" className={styles.confirmButton} onClick={handleConfirm}>
                        Konfirmo rezervimin
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CheckoutCar;
