// CarCleaningPage.js
import React from 'react';
import styles from '../styles/CarCleaningPage.module.css';

function CarCleaningPage() {
    return (
        <div className={styles.carCleaningContainer}>
            <header className={styles.header}>
            </header>


            <div className={styles.overlay}>
                <h1 className={styles.title}>Pastrim profesional i makinave</h1>
                <p className={styles.subtitle}>Rigjallëroni automjetin tuaj me shërbimet tona të ekspertëve</p>
            </div>

            <section className={styles.carCleaningContent}>
                <div className={styles.dynamicSection}>
                    <div className={styles.dynamicText}>
                        <h2>Pastrim i brendshëm dhe i jashtëm</h2>
                        <p>
                            Ne jemi të specializuar në pastrimin e plotë të makinave, brenda dhe jashtë. Shërbimet tona përfshijnë pastrimin e brendshëm,
                            pastrimi i tapicerieve, pastrimi i dritareve dhe larja e jashtme. Makina juaj do të shkëlqejë brenda dhe jashtë.
                        </p>
                    </div>
                    <img
                        src="https://i.insider.com/5e69490554f25c19106866f2?width=2000&format=jpeg&auto=webp"
                        alt="Car Cleaning 1"
                        className={styles.dynamicImage}
                    />
                </div>

                <div className={styles.dynamicSection}>
                    <img
                        src="https://automanager.blob.core.windows.net/wmclients/024477/images/auto_detailing_at_gt_auto_lounge.jpg"
                        alt="Car Cleaning 2"
                        className={styles.dynamicImage}
                    />
                    <div className={styles.dynamicText}>
                        <h2>Mjetet e pastrimit më të fundit</h2>
                        <p>
                            Ne përdorim mjete pastrimi moderne dhe efikase për të siguruar rezultatet më të mira. Nga lustrimet e avancuara në
                            agjentë pastrimi me cilësi të lartë, automjeti juaj do të marrë kujdes të nivelit të lartë.
                        </p>
                    </div>
                </div>

                <div className={styles.dynamicSection}>
                    <div className={styles.dynamicText}>
                        <h2>Lustrim profesional i makinave</h2>
                        <p>
                            Ngritni pamjen e makinës suaj me shërbimet tona profesionale të lustrimit. Ne përdorim teknika të avancuara
                            për të nxjerrë shkëlqimin dhe për të mbrojtur bojën e makinës suaj.
                        </p>
                    </div>
                    <img
                        src="https://lirp.cdn-website.com/fd8c99f3/dms3rep/multi/opt/mobile+auto+detailing-640w.jpeg"
                        alt="Car Cleaning 3"
                        className={styles.dynamicImage}
                    />
                </div>

                {/* Additional Section 1 */}
                <div className={styles.dynamicSection}>

                    <img
                        src="https://inoutcarwash.com/wp-content/uploads/2019/09/auto-detailing-scaled.jpg"
                        alt="Car Cleaning 4"
                        className={styles.dynamicImage}
                    />
                    <div className={styles.dynamicText}>

                        <h2>Produkte miqësore me mjedisin</h2>
                        <p>
                            Ne kujdesemi për mjedisin. Produktet tona të pastrimit janë miqësore me mjedisin, duke siguruar një pastërti me gaz
                            pa dëmtuar natyrën.
                        </p>
                    </div>
                </div>

                {/* Additional Section 2 */}
                <div className={styles.dynamicSection}>
                    <img
                        src="https://fortador.com/wp-content/uploads/2023/07/autodetailing-samochodu.png"
                        alt="Car Cleaning 5"
                        className={styles.dynamicImage}
                    />
                    <div className={styles.dynamicText}>
                        <h2>Veshje Mbrojtëse</h2>
                        <p>
                            Veshjet tona mbrojtëse shtojnë një shtresë shtesë mbrojtjeje në makinën tuaj, duke e mbrojtur atë nga mjedisi
                            elementet dhe ruajtjen e shkëlqimit të saj.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CarCleaningPage;
