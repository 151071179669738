// Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import styles from '../styles/Footer.module.css';

const Footer = () => {
    const googleMapsLink = 'https://maps.app.goo.gl/TQsBRJnfSihJmR5C9';

    return (
        <footer className={styles.footer}>
            <p className={styles.location}>
                Lokacioni yne:
                <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">
                    <br />
                    Kosovë,
                    <br />
                    Suharek,
                    <br />
                    Studençan rruga Gurra
                </a>
            </p>
            <div className={styles.socialIcons}>
                <a href="https://www.facebook.com/autoplusrks1/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="https://www.instagram.com/autoplus.rks/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
