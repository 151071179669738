import { createContext, useContext, useState } from 'react';

const ReservationContext = createContext();

export const useReservationContext = () => useContext(ReservationContext);

export const ReservationProvider = ({ children }) => {
  const [reservationData, setReservationData] = useState(null);

  const setReservation = (data) => {
    setReservationData(data);
  };

  return (
    <ReservationContext.Provider value={{ reservationData, setReservation }}>
      {children}
    </ReservationContext.Provider>
  );
};
