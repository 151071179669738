// Vullkanizer.js

import React from 'react';
import styles from '../styles/Vullkanizer.module.css';

function Vullkanizer() {
    return (
        <div className={`container-fluid ${styles.vullkanizerContainer}`}>
            <header className={styles.header}>
                <div className={styles.overlay}>
                    <h1 className={styles.title}>Auto plus Vullkanizer</h1>
                    <p className={styles.subtitle}>Partneri juaj i besuar per problemet me Goma</p>
                </div>
            </header>

            <div className={`container ${styles.vullkanizerContent}`}>
                <p>
                    Mirë se vini në Auto Plus, ku ne ripërcaktojmë shërbimet e gomave me përsosmëri dhe
                    angazhim. Specializuar në ritretje të gomave, shitje dhe depozitim të gomave pa probleme
                    shërbime, ne jemi të përkushtuar për të ofruar zgjidhje të nivelit të lartë për nevojat tuaja të gomave.
                    Eksploroni shërbimet dhe produktet tona gjithëpërfshirëse më poshtë.
                </p>

                <div className={`row ${styles.servicesSection}`}>
                    {/* <div className={`col-md-4 ${styles.service}`}>
                        <h2>Drejtim i gomave</h2>
                        <p>
                            Siguroni performancën optimale të automjetit tuaj me shërbimet tona precize të shtrirjes së rrotave.
                            Ne përdorim teknologji të avancuar për të rreshtuar rrotat tuaja, duke rritur sigurinë dhe jetëgjatësinë e gomave
                        </p>
                        <br></br>
                        <img
                            src="https://www.topgear.com/sites/default/files/2022/07/6_0.jpg"
                            alt="Service 1"
                            className={`img-fluid ${styles.servicePhoto}`}
                        />
                    </div> */}

                    <div className={`col-md-4 ${styles.service}`}>
                        <h2>Balancimi i gomave</h2>
                        <p>
                            Përjetoni një udhëtim të qetë dhe të rehatshëm me shërbimet tona të balancimit të gomave. Ne
                            përdorni pajisje moderne për të balancuar gomat tuaja, duke reduktuar dridhjet dhe
                            përmirësimi i konsumit të gomave.
                        </p>
                        <img
                            src="/Photos/balancim_gomave.jpg"
                            alt="Service 2"
                            className={`img-fluid ${styles.servicePhoto}`}
                        />
                    </div>

                    <div className={`col-md-4 ${styles.service}`}>
                        <h2>Inspektimi i gomave</h2>
                        <p>
                            Siguroni sigurinë e gomave tuaja me shërbimet tona të inspektimit të plotë të gomave. Tona
                            teknikë me përvojë do të vlerësojnë gjendjen e gomave tuaja dhe do t'ju ofrojnë
                            rekomandime për mirëmbajtje ose zëvendësim.
                        </p>
                        <br></br>
                        <img
                            src="https://www.topgear.com/sites/default/files/2022/07/6_0.jpg"
                            alt="Service 3"
                            className={`img-fluid ${styles.servicePhoto}`}
                        />
                    </div>
                </div>

                <div className={`row ${styles.additionalSection}`}>
                    <div className={`col-md-6 ${styles.additionalItem}`}>
                        <img
                            src="/Photos/vullkanizer_goma_depo.jpg"
                            alt="Additional Photo 1"
                            className={`img-fluid ${styles.brandPhoto}`}
                        />
                        <h3>Shërbimi i depozitimit të gomave</h3>
                        <p>
                            Ruani gomat tuaja në mënyrë të sigurt me shërbimin tonë të përshtatshëm të depozitimit të gomave. Merr
                            ato pa probleme sa herë që keni nevojë. Gomat tuaja janë në duar të mira.
                        </p>
                    </div>
                    {/* <div className={`col-md-6 ${styles.additionalItem}`}>
                        <img
                            src="https://www.topgear.com/sites/default/files/2022/07/6_0.jpg"
                            alt="Additional Photo 2"
                            className={`img-fluid ${styles.brandPhoto}`}
                        />
                        <h3>Additional Service 2</h3>
                        <p>
                            Another exciting service! Duis autem vel eum iriure dolor in hendrerit in
                            vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla
                            facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent
                            luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
                        </p>
                    </div> */}
                </div>

                {/* You can continue adding more sections based on your business needs */}
            </div>
        </div>
    );
}

export default Vullkanizer;